import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { Box, Button, Card, Flex, H1, H2, StickyFooter } from "../components";
import dailyImage from "../images/daily.png";

const StyledImage = styled.img.attrs({
  src: dailyImage,
})`
  position: absolute;
  top: -50px;
  width: 90px;
  display: block;
  left: 50%;
  margin-left: -30px;
`;

const Welcome = ({ data, exerciseId, imageUrls }) => {
  if (!data) {
    return null;
  }
  return (
    <>
      <Box>
        <H1>{data.welcomeTitle}</H1>
        <Card>
          <Flex>
            <Box width={75}>
              <H2>{data.welcomeSubtitle}</H2>
              <Box marginBottom={20}>{data.welcomeContent}</Box>
              <Box>
                <Link data-testid={exerciseId} to={`exercise/${exerciseId}`}>
                  <Button width={215}>{data.buttonStart}</Button>
                </Link>
              </Box>
            </Box>
            <Box width={25}>
              <StyledImage />
            </Box>
          </Flex>
        </Card>
      </Box>
      <StickyFooter />
    </>
  );
};

export default Welcome;
