import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Flex } from '../components';
import logo from '../images/astound_icon_blk@1x.svg';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled(Flex)`
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`;

const RotatingLogo = styled.img.attrs({
  src: logo,
})`
  animation: ${rotate360} infinite 1s linear;
  width: 80px;
  color: black;
  height: 80px;
`;

const Loader = () => (
  <Wrapper>
    <RotatingLogo />
  </Wrapper>
);

export default Loader;
