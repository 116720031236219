import React from "react";
import styled from "styled-components";
import { Router } from "@reach/router";
import moment from "moment";
import firebase from "./firebase";
import { Loader, Header } from "./components";
import { Welcome, Exercise } from "./views";

const Container = styled.div`
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  max-width: 420px;
  min-width: 320px;

  width: 100%;
  min-height: 100vh;
  padding: 50px 30px 160px;
  position: relative;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
    line-height: 1.5;
  }
  a {
    font-weight: 600;
    color: #ff5982;
  }
`;

const makeUrl = contentType => `/${contentType}`;

const retrieveDataOnce = async contentType => {
  const db = firebase.database();
  return db.ref(makeUrl(contentType)).once("value");
};

export const fetchData = async () => {
  const snapshot = await Promise.all([retrieveDataOnce("static"), retrieveDataOnce("exercises")]);
  const data = {
    static: snapshot[0].val(),
    exercises: snapshot[1].val(),
  };
  return { ...data };
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.date = moment().format("dddd, MMMM D, YYYY");
    this.index = this.todaysExercise()[1];
    // if App is called with data prop, use this for initial state – used for testing
    this.state = { data: props.data || null };
  }

  async componentDidMount() {
    const data = await fetchData();
    this.setState({ data });
  }

  todaysExercise() {
    const [date, index = -1] = JSON.parse(localStorage.getItem("next-exercise") || "[]");
    let nextExercise = [date, index];
    if (index === 7) {
      // TODO cycle has ended, reset
      nextExercise = [this.date, 0];
    } else if (date !== this.date) {
      nextExercise = [this.date, index + 1];
    }

    localStorage.setItem("next-exercise", JSON.stringify(nextExercise));
    return nextExercise;
  }

  getId() {
    const l = window.location;
    const n = Number(l.pathname.split("/").pop());
    return l.hash === "#force" && !isNaN(n) && n > 0 && n < 8 ? n - 1 : this.index;
  }

  render() {
    const { data } = this.state;
    if (!data) return <Loader />;

    const id = this.getId();
    const todaysExercise = data.exercises ? data.exercises[id] : { id: 0 };
    return (
      <Container>
        <Header date={this.date} />
        <Router>
          <Welcome
            data={data.static}
            exerciseId={todaysExercise.id}
            imageUrls={data.imageUrls}
            path="/"
          />
          <Exercise
            data={todaysExercise}
            imageUrls={data.imageUrls}
            path={`exercise/${todaysExercise.id}`}
          />
        </Router>
      </Container>
    );
  }
}

export default App;
