import React from "react";
import styled from "styled-components";
import { Box, H1, StickyFooter } from "../components";

const Img = styled.img`
  margin-top: 20px;
  max-width: 640px;
  width: 100%;
  display: block;
`;

const Exercise = ({ children, data, imageUrls = {}, location }) =>
  location.pathname.match(/inspiration$/) ? (
    children
  ) : (
    <>
      <Box>
        <H1>{data.title}</H1>
        <Box marginBottom={30}>
          {data.instructions.split("\n").map(p => (
            <p>{p}</p>
          ))}
        </Box>
        <Box>
          <Img src={`/exercises/${data["image-name"]}`} alt={data["image-name"]} />
        </Box>
      </Box>
      <StickyFooter />
    </>
  );

export default Exercise;
