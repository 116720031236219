import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyAx58Dd4IRfGpd0nWz_LPSdY_Fj7kadF5Q',
  authDomain: 'astound-bada7.firebaseapp.com',
  databaseURL: 'https://astound-bada7.firebaseio.com',
  projectId: 'astound-bada7',
  storageBucket: 'astound-bada7.appspot.com',
  messagingSenderId: '496566468148',
  appId: '1:496566468148:web:0eac13278e73d6feb01461',
  measurementId: 'G-F19H3E49P8',
};

firebase.initializeApp(config);

export default firebase;
