import styled from 'styled-components';

const Card = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  margin: 72px 0;
  padding: 30px;
`;

export default Card;
