import styled from 'styled-components';

const H2 = styled.h2`
  font-weight: 600;
  line-height: 1.5;
  font-size: 20px;
  margin: 0 0 10px;
`;

export default H2;
