import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../components";

const StickyFooter = styled.footer`
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 32px;
  box-sizing: border-box;
  padding: 32px;
  padding-top: ${props => (props.showForm ? "0" : "24px")};
  background: #57ffe2;
  justify-content: center;
  z-index: 2;

  & iframe {
    border: 0;
    margin: 0;
    padding: 0;
    height: 720px;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

export default ({ children }) => {
  const [showForm, setShowForm] = useState(false);
  return (
    <StickyFooter showForm={showForm}>
      {!showForm && (
        <>
          <p>
            Would you like to be a Creative Ambassador and help us build a great app experience?
          </p>
          <p>
            Your help would be incredibly valuable to us! Provide us with your email and we’ll be in
            contact with you soon.
          </p>
          <ButtonWrapper>
            <Button style={{ background: "#000", color: "#fff" }} onClick={() => setShowForm(true)}>
              Become a Creative Ambassador
            </Button>
          </ButtonWrapper>
        </>
      )}
      {showForm && (
        <>
          <Button
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              fontSize: 40,
              padding: "0 20px",
              background: "transparent",
            }}
            onClick={() => setShowForm(false)}
          >
            ×
          </Button>
          <iframe
            title="ambassador-form"
            src="https://cdn.forms-content.sg-form.com/25434ddd-89f9-11ea-9528-ba9f384e58d7"
          />
        </>
      )}
    </StickyFooter>
  );
};
