import styled from "styled-components";

const Button = styled.button`
  padding: 8px 30px;
  font-weight: 600;
  border-radius: 22px;
  background-color: #57ffe2;
  font-size: 16px;
  border: 0;
  line-height: 1.5;
  color: #000;
  margin: 0;
  cursor: pointer;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`;

export default Button;
