import styled from 'styled-components';
import logo from '../images/astound_horiz_blk@1x.svg';

const Image = styled.img.attrs({
  src: logo,
  alt: 'Logo',
})`
  display: block;
  height: 41px;
  margin-bottom: 20px;
`;

export default Image;
