import styled from "styled-components";

const Date = styled.div`
  color: #7f7f7f;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
`;

export default Date;
