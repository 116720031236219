import styled from "styled-components";

const Box = styled.div`
  position: relative;
  font-family: default;
  box-sizing: "border-box";
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  width: ${({ width }) => (width ? `${width}%` : "100%")};
`;

export default Box;
