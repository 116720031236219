import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "@reach/router";
import { Box, Date, Logo } from "./";

const Back = styled.p`
  position: absolute;
  top: 0;
  margin-top: -30px;
  & a {
    text-decoration: none;
    color: #000 !important;
  }
`;

export default ({ date }) => {
  const location = useLocation();

  return (
    <Box>
      {location.pathname !== "/" && (
        <Back>
          <Link to="/">&#60; Back</Link>
        </Back>
      )}
      <Box>
        <Logo />
      </Box>
      <Date>{date}</Date>
    </Box>
  );
};
